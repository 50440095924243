<template>
  <div>
    <div v-show="sendMail" class="notification is-primary is-light">
      <strong>Link enviado!</strong><br />Basta clicar para redefinir sua senha
    </div>
    <div v-show="!sendMail">
      <p class="title">Esqueci minha senha</p>
      <p class="subtitle">Enviaremos o link para redefinir senha</p>
      <form @submit.prevent="onSubmit">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input
              v-model="user.email"
              class="input"
              type="email"
              placeholder="Email"
              required
            />
          </div>
          <p class="help is-danger">{{ errors[0] }}</p>
        </div>

        <div class="level">
          <div class="field is-grouped">
            <div class="control">
              <button :disabled="request" class="button is-primary">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecoverPassword",
  data() {
    return { user: {}, request: false, sendMail: false };
  },
  created() {
    this.request = false;
    this.sendMail = false;
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.request = true;
        this.$http
          .post(
            `${process.env.VUE_APP_URI}auth/clinic/recover_password`,
            this.user
          )
          .then(() => {
            this.request = false;
            this.sendMail = true;
            this.$buefy.toast.open({
              message: "Email enviado",
              position: "is-top-right",
              type: "is-success",
            });
          })
          .catch((err) => {
            if (err.response.status == 404)
              this.$refs.form.setErrors({ email: "Email não cadastrado" });
          });
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      });
    },
  },
};
</script>
